import React from 'react';
import { motion } from 'framer-motion';
import Tippy from '@tippyjs/react'; // Tooltip library
import 'tippy.js/dist/tippy.css'; // Tooltip styles

const projects = [
  {
    name: 'GraphPathsCalculator',
    description: 'This C++ application was implemented in order to explore the capabilities of graphs.',
    github: 'https://github.com/ManuelCLopes/GraphPathsCalculator',
    technologies: ['cplusplus'],
  },
  {
    name: 'Restaurant Management Desktop App',
    description: 'This application was implemented in Python in order to helps the management of restaurants. I used Tkinter python module in front-end and PostgreSQL11 as database server.',
    github: 'https://github.com/ManuelCLopes/RestaurantManagement',
    technologies: ['python', 'postgresql'],
  },
  {
    name: 'EasyScan',
    description: 'Native OCR mobile application integrated with Firebase and Google Cloud Functions',
    github: 'https://github.com/ManuelCLopes/EasyScan',
    technologies: ['kotlin', 'googlecloud', 'firebase'],
  },
  {
    name: 'Real-time Monitoring Safety System',
    description: 'An Intelligent and Scalable IoT Monitoring Framework for Safety in Civil Construction workspaces and other sectors',
    github: 'https://link.springer.com/chapter/10.1007/978-3-031-14859-0_6',
    technologies: ['python', 'kafka', 'rabbitmq', 'activemq', 'docker'],
  },
  {
    name: 'Finance Tracker Mobile App',
    description: 'This is a Finance Tracker application built with Flutter, designed to help users manage their expenses, income, and investments efficiently. The app supports multiple languages and provides features such as adding transactions, calculating net worth, and viewing an overview of financial activities.',
    github: 'https://github.com/ManuelCLopes/finance_tracker',
    technologies: ['flutter', 'dart'],
  },
  {
    name: 'Finance Tracker Web App',
    description: 'A web-based application that allows users to visualize their financial data using various charts. Users can upload their financial data by scanning a QR code generated by the application and view the visualized data in real-time.',
    github: 'https://github.com/ManuelCLopes/finance_dashboard',
    technologies: ['python', 'django', 'javascript', 'heroku'],
  },
];

const techLogos = {
  python: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg', label: 'Python' },
  cplusplus: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg', label: 'C++' },
  postgresql: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg', label: 'PostgreSQL' },
  django: { url: 'https://www.svgrepo.com/show/353657/django-icon.svg', label: 'Django' }, 
  javascript: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', label: 'Javascript' },
  react: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', label: 'React' },
  docker: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg', label: 'Docker' },
  flutter: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg', label: 'Flutter' },
  dart: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dart/dart-original.svg', label: 'Dart' },
  firebase: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg', label: 'Firebase' },
  googlecloud: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/googlecloud/googlecloud-original.svg', label: 'GCP' },
  kotlin: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kotlin/kotlin-original.svg', label: 'Kotlin' },
  rabbitmq: { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rabbitmq/rabbitmq-original.svg', label: 'RabbitMQ' },
  activemq: { url: 'https://activemq.apache.org/assets/img/activemq_logo_icon.svg', label: 'ActiveMQ' }, 
  kafka: { url: 'https://openwhisk.apache.org/images/icons/icon-kafka-white-trans.png', label: 'Kafka' },
  heroku: { url: 'https://www.svgrepo.com/show/331424/heroku.svg', label: 'Heroku' },
};

const Projects = () => {
  return (
    <div className="p-12 bg-gray-900 text-white">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {projects.map((project) => (
          <motion.a
            key={project.name}
            href={project.github}
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            className="relative p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 border-2 border-transparent hover:border-blue-400"
          >
            <h3 className="text-2xl font-bold mb-2">{project.name}</h3>
            <p className="text-gray-400 mb-4">{project.description}</p>
            <div className="flex space-x-4 mb-4">
              {project.technologies.map((tech) => (
                <Tippy key={tech} content={techLogos[tech].label}>
                  <img
                    src={techLogos[tech].url}
                    alt={techLogos[tech].label}
                    className="w-8 h-8 hover:scale-110 transition-transform"
                  />
                </Tippy>
              ))}
            </div>
          </motion.a>
        ))}
      </div>
    </div>
  );
};

export default Projects;
